import { createApp, toRef } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import LeftSidebar from '@/components/Left-Sidebar'
import RightSidebar from '@/components/Right-Sidebar'
import Report from '@/components/Report'
import Loader from '@/components/Loader'
import router from './router'
import { useLoadingState } from './store/loadingState'
import { useReportLink } from './store/reportLink'
import QASidebar from '@/components/QA-Sidebar'
import DropBox from '@/components/dropbox'
import DropBoxInp from '@/components/dropbox-search'
import VueApexCharts from "vue3-apexcharts";

// echart

import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import { BarChart } from 'echarts/charts';
import { LineChart } from 'echarts/charts';
import { MapChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DatasetComponent,
  GridComponent,
  GeoComponent,
  VisualMapComponent
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DatasetComponent,
  GridComponent,
  BarChart,
  LineChart,
  GeoComponent,
  VisualMapComponent,
  MapChart
]);


//echart


// import ClickOutside from 'vue-click-outside'

// core ui

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'

const app = createApp(App)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)
app.use(VueApexCharts);
app.component('v-chart', VChart)

app.use(CoreuiVue)
app.provide('loadingState', useLoadingState())
app.provide('reportLink', useReportLink())

app.use(router)
app.use(toRef)
// app.directive('click-outside', ClickOutside)
app.component('Header', Header)
app.component('Footer', Footer)
app.component('LeftSidebar', LeftSidebar)
app.component('RightSidebar', RightSidebar)
app.component('QASidebar', QASidebar)
app.component('Loader', Loader)
app.component('Report', Report)
app.component('DropBox', DropBox)
app.component('DropBoxInp', DropBoxInp)

app.mount('#app')