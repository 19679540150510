
import { useReportUsers } from '@/store/reportUsers'
import { useReportCIDs } from '@/store/reportCIDs'
import { $api } from '@/api_client'

export function loadAttachedInfo (id) {
	const usersStore = useReportUsers()
	const cidsStore = useReportCIDs()

	const loadUsers = (id) => {
		$api.get('/api/attached-users/'+id)
			.then((res) => {
				if(res.length){
					usersStore.reportUsersList(res)
				}else{
					usersStore.reportUsersList(false)
				}
			})
			.catch((error_data) => {
				console.log(error_data)
			})
	}

	const loadCids = (id) => {
		$api.get('/api/relationship-cids/'+id)
			.then((res) => {
				if(res.length){
					cidsStore.reportCIDsList(res)
				}else{
					cidsStore.reportCIDsList(false)
				}
			})
			.catch((error_data) => {
				console.log(error_data)
			})
	}

	return{
		loadUsers,
		loadCids
	}
}