
import { defineStore } from 'pinia'

export const useReportCIDs = defineStore('reportCIDs', {
	state: () => ({
		reportCIDs: false,
	}),

	actions: {
		reportCIDsList (data) {
			this.reportCIDs = data
		}
	}
})
