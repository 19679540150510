
import { defineStore } from 'pinia'

export const useInfoMessagesStore = defineStore('InfoMessagesStore', {
	state: () => ({
		toasts: []
	}),

	actions: {
		addSuccess (title = 'Good', content = '') {
			this.toasts.push({
				title,
				content,
				color: 'success',
				autohide: true,
				delay: 2000
			})
		},

		addError (title = 'Bad', content = '') {
			this.toasts.push({
				title,
				content,
				color: 'danger',
				autohide: true,
				delay: 3000
			})
		},

		deleteMessage (index) {
			this.toasts = this.toasts.splice(index, 1)
		}

	}
})
