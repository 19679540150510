
import { defineStore } from 'pinia'

export const useReportUsers = defineStore('reportUsers', {
	state: () => ({
		reportUsers: false,
	}),

	actions: {
		reportUsersList (data) {
			this.reportUsers = data
		}
	}
})
