
import { defineStore } from 'pinia'

export const useLoadingState = defineStore('loadingState', {
	state: () => {
		return {
			showLoader: false
		}
	},

	actions: {
		toogleLoaderState () {
			this.showLoader = !this.showLoader
		},

		setShowLoader (state = true) {
			this.showLoader = state
		}
	}
})
